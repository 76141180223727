import { Configuration, LogLevel } from '@azure/msal-browser';

const clientId = process.env.REACT_APP_AAD_APP_CLIENTID || 'missing-client-id';
const authority = process.env.REACT_APP_AAD_AUTHORITY || 'missing-authority';
const knownAuthorities = (
    process.env.REACT_APP_AAD_KNOWN_AUTHORITIES || 'missing-known-authorities'
).split(' ');

export const msalConfig: Configuration = {
    auth: {
        clientId: clientId,
        authority: authority,
        redirectUri: window.location.origin,
        knownAuthorities: knownAuthorities,
        postLogoutRedirectUri: window.location.origin + '/',
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};
